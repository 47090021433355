import { FC, lazy, useEffect, useState } from "react";
import { useTrackingInfo } from "tracking/hooks/use-tracking-info";
import { InactiveTracking } from "../inactive-tracking/inactive-tracking";
import { TrackingStatus, TrackingTemplate, TrackingWaypoint } from "tracking/tracking.types";
import { FinishedTracking } from "../finished-tracking/finished-tracking";
import { useCurrentLocation } from "tracking/hooks/use-current-location";

const SimpleTemplate = lazy(() => import("tracking/templates/simple-template/simple-template"));
const CompactTemplate = lazy(() => import("tracking/templates/compact-template/compact-template"));
const ExplorerTemplate = lazy(() => import("tracking/templates/explorer-template/explorer-template"));
const OverlayTemplate = lazy(() => import("tracking/templates/overlay-template/overlay-template"));
const PaladinTemplate = lazy(() => import("tracking/templates/paladin-template/paladin-template"));
const DataTemplate = lazy(() => import("tracking/templates/data-template/data-template"));
const VerdictTemplate = lazy(() => import("tracking/templates/verdict-template/verdict-template"));
const MotiveTemplate = lazy(() => import("tracking/templates/motive-template/motive-template"));

type Props = {
  routeId: string;
};

export const Tracking: FC<Props> = ({
  routeId,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const {
    trackingInfo,
  } = useTrackingInfo(routeId);

  const {
    currentLocation,
  } = useCurrentLocation(trackingInfo?.truckPoint);

  const truckPoint = trackingInfo?.truckPoint;

  useEffect(() => {
    if (!map?.getZoom()) {
      map?.setZoom(12);
    }
  }, [map]);

  useEffect(() => {
    if (!map?.getCenter() && truckPoint) {
      map?.setCenter(truckPoint);
    }
  }, [map, truckPoint]);

  useEffect(() => {
    if (!map || !trackingInfo?.theme) {
      return;
    }
    map.setOptions({ styles: trackingInfo.theme.mapStyles, streetViewControl: false, fullscreenControl: trackingInfo.theme.template !== TrackingTemplate.OVERLAY });
  }, [trackingInfo, map]);

  const handleNavigateToWaypoint = (waypoint: TrackingWaypoint) => {
    if (!waypoint.location) {
      return;
    }

    map?.setCenter(waypoint.location);
  };

  const handleNavigateToTruck = () => {
    if (!truckPoint) {
      return;
    }

    map?.setCenter(truckPoint);
  };

  if (trackingInfo?.status === TrackingStatus.FINISHED) {
    return <FinishedTracking />;
  }

  if (trackingInfo && !trackingInfo?.isActive) {
    return <InactiveTracking />;
  }

  if (!trackingInfo) {
    return null;
  }

  if (trackingInfo?.theme.template === TrackingTemplate.SIMPLE) {
    return (
      <SimpleTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.COMPACT) {
    return (
      <CompactTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.EXPLORER) {
    return (
      <ExplorerTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.OVERLAY) {
    return (
      <OverlayTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.PALADIN) {
    return (
      <PaladinTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.DATA) {
    return (
      <DataTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.VERDICT) {
    return (
      <VerdictTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  } else if (trackingInfo?.theme.template === TrackingTemplate.MOTIVE) {
    return (
      <MotiveTemplate
        setMap={setMap}
        trackingInfo={trackingInfo}
        navigateToTruck={handleNavigateToTruck}
        navigateToWaypoint={handleNavigateToWaypoint}
        currentLocation={currentLocation}
      />
    );
  }

  return null;
};
