import { ETA, LatLngLiteral, LoadInfo, WaypointType } from "shared/shared.types";

export enum TrackingStatus {
  PICK_UP = "PICK_UP",
  DROP_OFF = "DROP_OFF",
  REST = "REST",
  ROLLING = "ROLLING",
  FINISH_SCHEDULED = 'FINISH_SCHEDULED',
  FINISHED = "FINISHED",
};

export type TrackingWaypoint = {
  id: string;
  address: string;
  location: LatLngLiteral;
  type?: WaypointType;
}

export type TrackingTheme = {
  mapStyles?: any;
  template: TrackingTemplate;
};

export type TrackingInfo = {
  ETAs: ETA[];
  speed: number;
  status: TrackingStatus;
  truckPoint: LatLngLiteral;
  nextPoint?: LatLngLiteral;
  waypoints: TrackingWaypoint[];
  isActive: boolean;
  loadInfo: LoadInfo;
  theme: TrackingTheme;
  carrierName: string;
};

export enum TrackingTemplate {
  SIMPLE = 'SIMPLE',
  RETRO = 'RETRO',
  COMPACT = 'COMPACT',
  EXPLORER = 'EXPLORER',
  OVERLAY = 'OVERLAY',
  PALADIN = 'PALADIN',
  DATA = 'DATA',
  VERDICT = 'VERDICT',
  MOTIVE = 'MOTIVE',
}

export type TrackingTemplateProps = {
  trackingInfo: TrackingInfo;
  navigateToWaypoint: (waypoint: TrackingWaypoint) => void;
  navigateToTruck: VoidFunction;
  setMap: Function;
  currentLocation?: string;
};
