import { checkIfValidUUID } from 'shared/shared.helpers';
import { Tracking } from 'tracking/components/tracking/tracking';

function App() {
  const routeId = window.location.pathname.substring(1);

  if (!routeId || !checkIfValidUUID(routeId)) {
    return null;
  }

  return (
    <Tracking routeId={routeId} />
  );
}

export default App;
