export const InactiveTracking = () => {
  return (
    <div className="p-16">
      <b>Truck is offline, reconnecting...</b><br /><br />
      <div>
        Possible reasons
        <ul>
          <li>Transponder battery is low</li>
          <li>Transponder is out of coverage</li>
          <li>Problems with truck electrical system</li>
          <li>Problems with connectivity</li>
        </ul>
      </div>
      Wait for updates.<br />
      Thanks.
    </div>
  );
};
