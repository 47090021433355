import axios from "axios";

const instance = axios.create({
  // baseURL: 'https://api.freightmonitoring.com/',
  baseURL: window.location.hostname === "localhost" ? 'http://localhost:3333/' : 'https://api.freightmonitoring.com/',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
});

const constructAxiosOptions = () => {
  return {};
}

export const getJSON = (url: string) => {
  return instance.get(url, constructAxiosOptions());
}

export const postJSON = (url: string, payload?: any) => {
  return instance.post(url, payload, constructAxiosOptions());
}

export const patchJSON = (url: string, payload?: any) => {
  return instance.patch(url, payload, constructAxiosOptions());
}

export const putJSON = (url: string, payload?: any) => {
  return instance.put(url, payload, constructAxiosOptions());
}

export const deleteJSON = (url: string) => {
  return instance.delete(url, constructAxiosOptions());
}

// export const buildUrl = <T extends { [key: string]: any }>(path: string, queryParams?: T): string => {
//   if (!queryParams) {
//     return path;
//   }
//   const params = Object.keys(queryParams).reduce((acc: { [key: string]: any }, key) => {
//     if (queryParams[key] !== undefined && queryParams[key] !== null) {
//       acc[key] = queryParams[key];
//     }
//     return acc;
//   }, {});

//   return `${path}?${stringify(params, { sort: false })}`;
// };
