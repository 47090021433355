import { useEffect, useState } from "react";
import { usePrevious } from "shared/hooks/use-previous";
import { LatLngLiteral } from "shared/shared.types";

export const useCurrentLocation = (latLng?: LatLngLiteral) => {
  const [currentLocation, setCurrentLocation] = useState<string>();

  const truckPoint = latLng;
  const prevTruckPoint = usePrevious(truckPoint);
  
  useEffect(() => {
    if (truckPoint && (prevTruckPoint?.lat !== truckPoint?.lat || prevTruckPoint?.lng !== truckPoint?.lng)) {
      const { lat, lng } = truckPoint
      fetch(`https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}`)
        .then((response) => response.json())
        .then((response) => {
          console.log({response});
          setCurrentLocation(response.display_name);
        });
    }
  }, [truckPoint, prevTruckPoint]);

  return {
    currentLocation,
  };
};
