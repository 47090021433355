import { useCallback, useEffect, useState } from "react";
import { useInterval } from "shared/hooks/use-interval";
import { fetchTrackingInfoRequest } from "tracking/tracking.requests";
import { TrackingInfo } from "tracking/tracking.types";

const FETCH_INTERVAL = 10_000;

export const useTrackingInfo = (routeId: string) => {
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo>();

  const fetchInfo = useCallback(() => {
    fetchTrackingInfoRequest(routeId)
      .then(({ data }) => {
        setTrackingInfo(data);
      });
  }, [routeId]);

  useEffect(fetchInfo, [fetchInfo]);

  useInterval(fetchInfo, FETCH_INTERVAL);

  return {
    trackingInfo,
  };
};
